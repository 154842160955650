import { on } from 'delegated-events';

import showPopup from './modals';
import smallCartRefresh from './cart/small-cart-refresh';
import { showLoader, hideLoader } from './utils/utils';

export default function () {
    on('click', '.cartadder', function () {
        showLoader();

        const scope = this.closest('[data-scope="addcart"]');
        let amount;

        if (scope.querySelector('[data-role="product-amount"]')) {
            amount = Number(scope.querySelector('[data-role="product-amount"]').value) || 1;
        } else {
            amount = 1;
        }

        if (scope.querySelector('[data-optionid]') && scope.querySelector('[data-optionid]').getAttribute('data-optionid') === '-1') {
            showPopup({ body: 'Proszę wybrać opcję produktu', overloadClass: 'error-modal' });
            hideLoader();
            return;
        }

        const requestBody = {
            request: {
                Amount: amount,
                ProductId: scope.querySelector('[data-pid]').getAttribute('data-pid'),
                OptionId: scope.querySelector('[data-optionid]') ? scope.querySelector('[data-optionid]').getAttribute('data-optionid') : null
            }
        };

        const requestAddons = [];

        scope.querySelectorAll('[data-cart-custom]').forEach(dataCart => {
            requestAddons.push({
                key: dataCart.getAttribute('data-cart-custom'),
                value: dataCart.value
            });
        });

        requestBody.request['addons'] = requestAddons;

            fetch('cartaction/addcart', {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
            .then(response => response.json())
            .then(data => {

                hideLoader();

                if (data.RedirectToUrl) {
                    location.href = data.ProductUrl;
                    return false;
                }

                showPopup({
                    header: 'Dodano do wyceny!',
                    body: data.HtmlResponse,
                    overloadClass: 'cart-modal success-modal',
                    anotherCloseSelector: '.return-button',
                    leftLinks: '<span class="return-button active-button">Szukam dalej</span>',
                    rightLinks: '<a class="confirm-button active-button" href="/cart">Przejdź do zapytania</a>'
                });

                if (data.PositionChanges) {
                    for (const item of data.PositionChanges) {

                        document.dispatchEvent(new CustomEvent('goshop:cart_action', { detail: item }));

                    }
                }

                smallCartRefresh();
            })
            .catch(error => {
                hideLoader();
                console.log(error);
            });
    });
}